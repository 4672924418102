import { graphql } from 'gatsby';
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import WorksContainer from '../components/containers/WorksContainer';
import Layout from '../components/Layout'
import Seo from '../components/SEO'
import { usePageContext } from '../pageContext';

function WorksPage({ location, data }) {

  const languageData = useTranslation()[1].store.data;

  const { lang } = usePageContext();

  const echo = (expression) => {
    return languageData[lang]['worksPage'][expression];
  }

  return (
    <Fragment>

      <Layout data={data} lang={lang}>
        <Seo title={`Hanniel TSASSE - ${lang == 'fr' ? 'Mes travaux' : 'My works'}`} lang='fr' description={echo('description')} />

        <WorksContainer lang={lang} />
      </Layout>
    </Fragment>
  )
}

export default WorksPage;

export const query = graphql`
  query WorksPageQuery {
    site {
      siteMetadata {
        siteURL
      }
    }
  }`