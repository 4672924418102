import React from 'react'
import { useTranslation } from 'react-i18next';
import { usePageContext } from '../../pageContext';
import Link from '../Link'

function ProjectItem({ project, lang }) {

    const languageData = useTranslation()[1].store.data;

    const echo = (expression) => {
        return languageData[lang]['worksPage'][expression];
    }

    return (
        <div className={["art-grid-item", project.categories].join(' ')}>
            
            {/* <!-- grid item frame --> */}
            <a data-fancybox="gallery" href={`./../../${project.image.src}`} className="art-a art-portfolio-item-frame art-square">
                {/* <!-- img --> */}
                <img src={`${project.image.small}`} alt="item" />
                {/* <!-- zoom icon --> */}
                <span className="art-item-hover"><i className="fas fa-expand"></i></span>
            </a>
            {/* <!-- grid item frame end --> */}

            {/* <!-- description --> */}
            <div className="art-item-description">
                {/* <!-- title --> */}
                <h5 className="mb-15">{project.title[lang]}</h5>
                <div className="mb-15">{project.description[lang]}</div>
                {/* <!-- button --> */}
                <Link to={`/mes-travaux/${project.slug}`} className="art-link art-color-link art-w-chevron">{lang == 'fr' ? "Lire plus" : "Read More"}</Link>
            </div>
            {/* <!-- description end --> */}

        </div>
    )
}

export default ProjectItem
