import React from 'react'
import { useTranslation } from 'react-i18next';
import { usePageContext } from '../../pageContext';
import Projects from '../../providers/Projects'
import ProjectItem from '../elements/ProjectItem'

function WorksContainer({ lang }) {

    const languageData = useTranslation()[1].store.data;

    const echo = (expression) => {
        return languageData[lang]['similarPostsContainer'][expression];
    }

    return (
        <div className="container-fluid">

            {/* <!-- row --> */}
            <div className="row p-30-0">

                {/* <!-- col --> */}
                <div className="col-lg-12">

                    {/* <!-- section title --> */}
                    <div className="art-section-title">
                        {/* <!-- title frame --> */}
                        <div className="art-title-frame">
                            {/* <!-- title --> */}
                            <h4>{lang == 'fr' ? 'Mes travaux' : 'My Works'}</h4>
                        </div>
                        {/* <!-- title frame end --> */}
                        {/* <!-- right frame --> */}
                        <div className="art-right-frame">
                            {/* <!-- filter --> */}
                            <div className="art-filter">
                                {/* <!-- filter link --> */}
                                <a href="#" data-filter="*" className="art-link art-current">{lang == 'fr' ? 'Toutes les Catégories' : 'All Categories'}</a>
                                {/* <!-- filter link --> */}
                                <a href="#" data-filter=".webApps" className="art-link">{lang == 'fr' ? 'Applications Web' : 'Web Applicatins'}</a>
                                {/* <!-- filter link --> */}
                                <a href="#" data-filter=".mobileApps" className="art-link">{lang == 'fr' ? 'Applications Mobile' : 'Mobile Applications'}</a>
                                {/* <!-- filter link --> */}
                            </div>
                            {/* <!-- filter end --> */}
                        </div>
                        {/* <!-- right frame end --> */}
                    </div>
                    {/* <!-- section title end --> */}

                </div>
                {/* <!-- col end --> */}

                <div className="art-grid art-grid-3-col art-gallery">
                    {Projects.map((project, index) => <ProjectItem lang={lang} key={index} project={project} />)}
                </div>

            </div>
            {/* <!-- row end --> */}

        </div>

    )
}

export default WorksContainer
